import { createMedia } from '@artsy/fresnel';
import { Menu } from 'semantic-ui-react'

export const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

export const menuItems = ['Home', 'Contact Us', 'Document Verifier', 'Privacy Policy']