import React from 'react'
import {
    Button,
    Container,
    Header,
    Icon,
} from 'semantic-ui-react'
import './HeaderContainer.css';


const HeaderContainer = (props) => {
    const { view } = props

    return (
        <Container text>
            <Header
                as='h1'
                content={view === "Home"? <div style={{marginTop:'120px'}}><img src='logo.png'/></div> : view.toUpperCase()}
                inverted
                className={"site-header"}
            />
            <Header
                as='h2'
                content='Financial Technology For You'
                inverted
                className={"site-sub-header"}
            />
            <Button primary size='huge' as='a' href='https://systems.bondstream.trade' target="_blank">
                Get Started
          <Icon name='arrow right' />
            </Button>
        </Container>
    )
}

export default HeaderContainer;