import { ResponsiveContainer } from "../common";
import { HomePage } from "../containers"
import './App.css';

function App() {
  return (
    <ResponsiveContainer>
      <HomePage></HomePage>
    </ResponsiveContainer>
  );
}

export default App;
