import React from 'react'
import {
    Header,
    Container,
    Segment,
    Button,
    Divider,
    Icon
  } from 'semantic-ui-react'

const BlogSection = () => {
    return (
        <Segment style={{ padding: '8em 0em' }} vertical>
          <Container text>
            <Header as='h3' style={{ fontSize: '2em' }}>
              BlockChain Digital Signature
            </Header>
            <p style={{ fontSize: '1.0em' }}>
            Our distributed ledger implementation facilitates digital signing of documents. 
            With the latest release verification of authenticity can be achieved with absolute 
            certainty. The document verification function uses transaction related metadata including
             ISIN reference and timestamping. Documents can be verified both within and outside of the Bondstream environment.
            </p>
            <p style={{ fontSize: '1.0em' }}>
            The technology provides the world’s first EU eIDAS’ accredited blockchain technology 
            and is used widely by both European governments and the US Department of Defense.
            </p>
            <Button primary size='huge' as='a' href="https://debtadviserbrca.medium.com/buyside-transaction-dashboard-and-blockchain-for-bondstream-af7428f689cb"
            target="_blank">
                Read More
              <Icon name='arrow right' />
            </Button>
            <Divider
              as='h4'
              className='header'
              horizontal
              style={{ margin: '3em 0em', textTransform: 'uppercase' }}
            >
              <a href='#'>Blog and News</a>
            </Divider>
    
            <Header as='h3' style={{ fontSize: '2em' }}>
              News and Blogs
            </Header>
            <p style={{ fontSize: '1.0em' }}>
              We regularly post relevant news, updates, trends, and industry information. You 
              can subscribe to our blog so as not to miss out on relevant industry information 
              and different market trends which are constantly changing. 
            </p>
            <Button primary size='huge' as='a' target="_blank" href="https://debtadviserbrca.medium.com/">
                Read Blog
              <Icon name='arrow right' />
            </Button>
          </Container>
        </Segment>
    )
}

export default BlogSection;