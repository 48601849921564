import React, { useState } from 'react'
import {
    Button,
    Form,
    Message,
    Container
} from 'semantic-ui-react'
import validator from 'validator'


const ContactForm = () => {
    const [data, setInput] = useState({ name: '', email: '', message: '', error: '', isError: false })

    const updateInput = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;

        setInput({ ...data, [name]: value, isError: false })
    }

    const submitForm = () => {
        if (data.message.length < 2) {
            setInput({ ...data, isError: true, error: "You have empty fields" })
        } else if (!validator.isEmail(data.email)) {
            setInput({ ...data, isError: true, error: "Please enter a valid Email" })
        }
    }

    const renderError = () => {
        if (data.isError) {
            return <Message negative>
                <Message.Header>Form Errors</Message.Header>
                <p>{data.error}</p>
            </Message>
        }
    }

    return (
        <Container>
            {renderError()}
            <Form>
                <Form.Input required name="name" value={data.name} fluid={"true"} label='Name' placeholder='Name' onChange={updateInput} />
                <Form.Input required name="email" value={data.email} type={"email"} fluid={"true"} label='Email' placeholder='Email' onChange={updateInput} />
                <Form.TextArea required name="message" value={data.message} fluid={"true"} label='Message' placeholder='Message' onChange={updateInput} />
                <Button primary size='huge' type='submit' onClick={submitForm}>Contact Us</Button>
            </Form>
        </Container>
    )
}

export default ContactForm;