import { axiosClient } from '../../api'

export async function fetchMandates() {
    return await axiosClient.get(`mandates`)
    .then((response) => {
        return response.data
    }).catch(error => {
        throw error
    })
}

export async function verifyDocument(file) {
    let formData = new FormData();
    formData.append("file", file)
    return await axiosClient.post(`verify-document`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    .then((response) => {
        return response.data
    }).catch(error => {
        throw error
    })
}