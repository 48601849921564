import React from 'react'
import asset_management from "../../../media/asset-management-illustration.jpg"
import {
    Grid,
    Header,
    Image,
    Segment,
    Button,
    Icon
  } from 'semantic-ui-react'

const IntroSection = () => {
    return (
        <Segment style={{ padding: '8em 0em' }} vertical>
          <Grid container stackable verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  WELCOME TO BONDSTREAM
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                    Bondstream is a disruptive B2B FinTech programme built for Issuers and similar players in the securities market. 
                    Bondstream provides a fast clear and practical overview of any issuances these may have. 
                    Our platform allows companies to have everything they need relating to any notes, issuers, clients and any other relevant information all in one place.
                </p>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  CRM
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                Bondstream combines the ease of use seen in many CRM systems and the functionality of a 
                securities settlement system into one coordinated flow of information.
                The Dashboard will show all key information and statistics relating to the most important series,
                all in one brief look.
                This allows companies to easily keep track of any incoming or outgoing trades. This also includes all information relating to buyers, sellers and intermediaries.
                </p>
              </Grid.Column>
              <Grid.Column floated='right' width={6}>
                <Image bordered rounded size='large' src={asset_management} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign='center'>
                <Button primary size='huge' as='a' href='https://systems.bondstream.trade' target="_blank">
                  Get Started
                  <Icon name='arrow right' />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
    )
}

export default IntroSection;