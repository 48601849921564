import React from 'react'
import map from "../../media/location-map.png"
import {
    Grid,
    Header,
    Segment,
    List
} from 'semantic-ui-react'
import './PrivacyPage.css'

const PrivacyPage = () => {
    return (
        <Segment style={{ padding: '3em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Privacy Policy
                        </Header>
                        <List>
                            <List.Item>
                                <List.Header as='h2'>What We Collect</List.Header>
                                <p>Arvop Teenused OÜ collects and keeps information about:<br />
how to reach you (such as name, title, firm, mailing and e-mail address, phone and fax number);<br />
how to bill you (such as bank information and billing address); and<br />
information about which specific markets, functional areas, and types of information are pertinent to you.<br /></p>

                                <p>We may also automatically collect technical information when you visit or
                                otherwise use our Site, such as your IP address, and information about your visit,
such as the pages that you viewed.</p>

                                <p>Subject to your rights, we may use the above information to: <br />
perform any contract which we may have with you;<br />
help us send you information that is useful to you;<br />
avoid bothering you with information that isn't useful to you;<br />
comply with our legal obligations; <br /> or protect our legitimate business interests e.g. to analyse the use of the Site or our services in order to continually improve them.<br /> </p>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>When We Collect</List.Header>
                                <p>We may collect your information from multiple sources.
                                We collect information through our Site when you fill out an online form or,
                                    respond to one of our e-mail notices or, otherwise communicate with us. </p>

                                <p>Further examples of ways in which we may collect your information include: <br />

when you participate in our due diligence exercises; <br />
when you attend one of our meetings; <br />
when you provide us with your business card; <br />
when you write to or otherwise communicate with us; or <br />
when you provide us with information orally. <br /> </p>

                                <p>By disclosing your information to us via methods such as those listed above, you acknowledge the collection, storage, and processing of your personal information by Arvop in the manner set out in this privacy policy.</p>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>What We Receive From Other People.</List.Header>
                                <p>We occasionally work closely with third parties who may collect personal
                                information from you and pass it on to us. Our staff may also give us
                                emergency contact information as part of our emergency scenario planning and may give us
                                details of their next of kin or dependants in relation to their employee benefits arrangements.
                                Where this is the case the third party is responsible for obtaining the relevant consents
                                from you to ensure you are happy with the ways in which your personal data will be used.</p>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>What We Do With It</List.Header>
                                <List.Content>
                                    <List>
                                        <List.Item>
                                            <List.Header as='h4'>Data gathered on Arvop forms:</List.Header>
                                            <p className={"small-font"}>When you submit an online form through our Site, we use the data to process your enquiry. Your email
                                        address is used where you have consented or where we are otherwise entitled to do so, to store the data provided so that it's easier for you to register for future events and to send you our newsletter or other communications.</p>
                                        </List.Item>
                                        <List.Item>
                                            <List.Header as='h4'>When you ask for a password:</List.Header>
                                            <p className={"small-font"}>If you're asked for some information when you apply for a password to secure pages,
                                            it's used to generate a password and communicate password and other pertinent
                                            information relating to your account to you.</p>
                                        </List.Item>
                                        <List.Item>
                                            <List.Header as='h4'>User Type:</List.Header>
                                            <p className={"small-font"}>The information you provide us with may also be used to help us identify the type
                                            of user you are, whether you are a Sponsor, Distributor, Broker,
                                            Sales Manager or representative of a High Net Worth individual or,
                                             any other wholesale investor.</p>
                                        </List.Item>
                                        <List.Item>
                                            <List.Header as='h4'>User Type:</List.Header>
                                            <p className={"small-font"}> You should be aware that, where you have consented, your business
                                            contact information may also be provided to third parties.
                                            In addition, and where applicable, your payment details
                                            may be provided to our third party services providers
                                            (such as our payment processors or Registrar) so that we can process your order(s).
                                        </p>
                                        </List.Item>
                                        <List.Item>
                                            <List.Header as='h4'>Compliance with Laws and Regulations:</List.Header>
                                            <p className={"small-font"}> We, or our third party service providers, may disclose information to comply
                                            with applicable laws and regulations, such as to
                                            respond to a subpoena or similar legal process, and to otherwise
                                            cooperate with law enforcement or regulatory authorities.
                                        </p>
                                        </List.Item>
                                        <List.Item>
                                            <List.Header as='h4'>Business Analysis:</List.Header>
                                            <p className={"small-font"}> Subject to your rights and in accordance with standard business practices,
                                            BRC and/or its third party services providers may use your data to monitor or
                                            analyse its business practices.
                                        </p>
                                        </List.Item>
                                        <List.Item>
                                            <List.Header as='h4'>Sending marketing communications:</List.Header>
                                            <p className={"small-font"}> Where you have consented, or where we are otherwise entitled to do so,
                                            your contact information may be used to send you information about Arvop's
                                            services that may be of interest to you by email. You may opt out of receiving
                                            marketing communications from us or our associated third parties by:
                                        </p>
                                        <ul>
                                            <li>contacting us at <a href='mailto:info@bondstream.trade'>info@bondstream.trade</a></li>
                                            <li>following the instructions in the relevant marketing communication.</li>
                                        </ul>
                                        </List.Item>
                                    </List>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>Data Accuracy and Retention</List.Header>
                                <p>We use reasonable endeavours to ensure that all personal information we
                                hold about you is accurate and up to date. Information which is incorrect,
                                or misleading is inaccurate, and steps will therefore be taken to check the
                                accuracy of any personal information. Inaccurate or outdated information will
                                be destroyed. Please assist us in keeping your information as accurate as
                                      possible by updating us in the event that anything changes.</p>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>Hyperlinks</List.Header>
                                <p>Our privacy policy does not apply to third party websites that may be accessible
                                through links from our Site (and we do not accept responsibility or liability
                                for the way in which your personal data is processed by the operators of any
                                such third party websites). We encourage you to read the privacy policies of
                                those third parties to learn how they collect and use information about you
                                      before using our sites or providing any personal data about yourself.</p>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>Your rights</List.Header>
                                <p>We will usually inform you (before collecting your information)
                                if we intend to use your information for marketing or research purposes
                                or if we intend to disclose your information to any third party for such purposes.
                                You can exercise your right to prevent such processing by checking certain boxes on
                                the forms we use to collect your data. You can also exercise the right at any time,
                                raise any queries or complaints in relation to how we use your information by
                                contacting us at <a href="mailto:info@bondstream.trade">info@bondstream.trade</a>. Should you wish to take any complaints
                                or queries further, you have the right to contact the Information Commissioner's
                                       Office regarding such issues.</p>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>Accessing and updating your information</List.Header>
                                <p>You have the right to see the information we hold about you and to ask us to: </p>
                                <p>make any changes to ensure that any information we hold about you is accurate and up to date; <br />
                                    erase or stop processing any information we hold about you where there is no longer a legal ground for us to hold it; or <br />
                                    in some cases, transfer any information we hold about you to a specified third party. If you wish to do this, please contact us using the contact details set out below.</p>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>Security, data transfers outside of the EEA and data retention</List.Header>
                                <p>We take steps to protect your information from unauthorised access and against unlawful processing,
                                 accidental loss, destruction and damage. We will only keep your information for as long as we reasonably require and, in any event, only for as long as Data Protection Legislation allows.</p>
                                <p>The data that we collect from you may be transferred to, and stored at,
                                a destination outside the European Economic Area ("EEA") or the United
                                Kingdom ("UK"). It may also be processed by staff operating outside the
                                EEA or the UK who work for us or third parties engaged in, among other things,
                                the provision of support services to us. By submitting your personal data,
                                you acknowledge this transfer, storing or processing.
                                We will take all steps reasonably necessary to ensure that your data
                                is treated securely and in accordance with this policy and the Data
                                Protection Legislation. This means that we will only transfer your
                                        information to third parties that:</p>
                                <p>Are in countries that have been confirmed by the European Commission to
                                    provide adequate protection to personal information; or</p>
                                <p>Have agreed to provide all protections to your personal information as set
                                    out in the Data Protection Legislation.</p>
                                <p>Unfortunately, the transmission of information via the internet is not completely secure.
                                Although we will do our best to protect your personal data, we cannot guarantee the
                                    security of your data transmitted to our site; any transmission is at your own risk.</p>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>Password Security</List.Header>
                                <p>The registration processes on our website may involve you giving a password and account designation. You are responsible for maintaining the confidentiality of the password and account and are fully responsible for all activities that occur under your password or account. </p>
                                <p> You agree to:<br />
                                        Immediately notify Arvop of any unauthorised use of your password or account and any other breach of security, and<br />
                                        Ensure that you exit from your account at the end of each session.<br />
                                        Arvop cannot and will not be liable for any loss or damage arising from your failure to comply with this obligation.</p>
                            </List.Item>
                            <List.Item>
                                <List.Header as='h2'>Changes to This Policy</List.Header>
                                <p>We reserve the right to update or otherwise vary this policy from time to
                                time, without notice to you. Your access to or use of our website at any time
                                is subject to this policy as then in effect.
                                It is your responsibility to review this policy regularly to make
                                       sure you are aware of any changes and comply with any changed policy. If you do not agree with any changes made to this policy or the terms of use for our Site, please do not continue to use our services or use or visit our Site.</p>
                            </List.Item>
                        </List>
                        <p>If you have any questions about our privacy policy, please contact <a href="mailto:info@bondstream.trade">info@bondstream.trade</a></p>
                        <p>Last reviewed <strong>May 2018</strong></p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default PrivacyPage;