import React from 'react'
import { MiscSection, IntroSection, BlogSection } from "../../bondstream/components"

const HomePage = () => {
    return (
        <div>
            <IntroSection />
            <MiscSection />
            <BlogSection />
        </div>
    )
}

export default HomePage;