import React, { useState } from 'react'
import { FooterContainer, HeaderContainer, MenuContainer } from '../../../common/'
import { HomePage, ContactPage, Verifier, PrivacyPage } from "../../../containers"
import {
  Segment,
  Visibility,
} from 'semantic-ui-react'

import { Media } from "../../../shared/shared"

const DesktopLayout = () => {
  const [active, setActiveMenu] = useState("Home")

  const updateMenu = (view) => {
    setActiveMenu(view)
  }

  const renderView = () => {
    if (active === "Document Verifier") {
        return <Verifier></Verifier>
    } else if (active === "Contact Us") {
      return <ContactPage></ContactPage>
    } else if (active === "Privacy Policy") {
      return <PrivacyPage></PrivacyPage>
    }  else {
      return <HomePage></HomePage>
    }
  }

  return (
    <Media greaterThan='mobile'>
      <Visibility
        once={false}>
        <Segment
          inverted
          textAlign='center'
          style={{ minHeight: 700, padding: '1em 0em', backgroundImage: 'url(/bgimage.jpg)' }}
          vertical>
          <MenuContainer active={active} updateMenu={updateMenu}></MenuContainer>
          <HeaderContainer view={active}/>
        </Segment>
        {renderView()}
        <FooterContainer updateMenu={updateMenu}></FooterContainer>
      </Visibility>
    </Media>
  )
}

export default DesktopLayout;