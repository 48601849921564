import React, { useState, useRef } from 'react'
import { verifyDocument } from '../../bondstream/api/api'

import {
    Grid,
    Header,
    Segment,
    Form,
    Progress,
    Button,
    Card,
    Icon
} from 'semantic-ui-react'

const Verifier = () => {
    const fileInputRef = useRef();

    const [object, setStatus] = useState({
        error: '',
        isError: false, inProgress: false, showResult: false, metadata: null
    })

    const fileChange = (e) => {
        const filename = e.target.files[0].name;
        if (filename === undefined || filename === null) return;
        setStatus({ ...object, inProgress: true, showResult: false, metadata: null })

        verifyDocument(e.target.files[0]).then(data => {
            if (data['message']['metadata']) {
                setStatus({ ...object, inProgress: false, showResult: true, metadata: data['message'] })
            } else {
                setStatus({ ...object, inProgress: false, showResult: true, isError: true })
            }

        }).catch(error => {
            setStatus({ ...object, inProgress: false, showResult: true, isError: true })
        })
    };

    const renderProgress = () => {
        if (object.inProgress) {
            return <Progress percent={30} active> Checking Signature  </Progress>
        }
    }

    const renderResult = () => {
        console.log(object.showResult)
        if (object.showResult) {
            if (object.isError) {
                return <Card.Group>
                <Card>
                    <Card.Content>
                        <Icon name="cancel" inverted color='red' size="big" className={"float-right"}></Icon>
                        <Card.Header>META DATA</Card.Header>
                        <Card.Meta><strong>Not Signed</strong></Card.Meta>
                        <Card.Description>
                            Document not digitally signed by bondstream.
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <div className='ui two buttons'>
                            <Button basic color='red'>
                                Not Signed
                            </Button>
                        </div>
                    </Card.Content>
                </Card>
            </Card.Group>
            } else {
                return <Card.Group>
                    <Card>
                        <Card.Content>
                            <Icon name="check circle outline" inverted color='green' size="big" className={"float-right"}></Icon>
                            <Card.Header>META DATA</Card.Header>
                            <Card.Meta><strong>Created At </strong> {new Date(+object.metadata.createdAt).toDateString()}</Card.Meta>
                            <Card.Description>
                                {
                                    Object.keys(object.metadata.metadata).map((value, index) => {
                                        return <p key={index}><strong>{value}: </strong>{object.metadata.metadata[value]}</p>
                                    })
                                }
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <div className='ui two buttons'>
                                <Button basic color='green'>
                                    Valid Document
                                </Button>
                            </div>
                        </Card.Content>
                    </Card>
                </Card.Group>
            }
        }
    }

    return (
        <Segment style={{ padding: '3em 0em' }} vertical>
            <Grid container stackable verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Header as='h3' style={{ fontSize: '2em' }}>
                            Document Verifier?
                        </Header>
                        <p style={{ fontSize: '1.1em' }}>
                            You can upload your document to confirm if it was digitally signed by bondstream. 
                        </p>
                        <p style={{ fontSize: '1.1em' }}>
                            Please note that if document is not digitally signed, you can directly contact us via 
                            email for clarification.
                        </p>
                        <Form>
                            <Form.Field>
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    hidden
                                    onChange={fileChange}
                                />
                                <Button
                                    content="Choose File"
                                    labelPosition="left"
                                    icon="file"
                                    onClick={() => fileInputRef.current.click()}
                                />
                            </Form.Field>
                            {renderProgress()}
                        </Form>
                    </Grid.Column>
                    <Grid.Column floated='right' width={6}>
                        {renderResult()}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default Verifier;