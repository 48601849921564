import devConfig from './dev/devConfig';
import prodConfig from './prod/prodConfig';

export let config;

const environment = process.env.NODE_ENV;

switch (environment) {
    case 'development':
        config = devConfig;
        break;
    case 'production':
        config = prodConfig;
        break;
    case 'test':
        config = devConfig;
        break;
    default:
        throw new Error("Invalid environment: "+environment);
}