import React from 'react'
import map from "../../media/location-map.png"
import { ContactForm } from "../../bondstream/components"
import {
  Grid,
  Header,
  Image,
  Segment,
  List
} from 'semantic-ui-react'

const ContactPage = () => {
  return (
    <Segment style={{ padding: '3em 0em' }} vertical>
      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '2em' }}>
              How can we help you?
                </Header>
            <p style={{ fontSize: '1.1em' }}>
              Use the form below to directly send us your query.
              Our team is ready to provide you with information and answers to any questions you may have.
                </p>
            <ContactForm></ContactForm>
          </Grid.Column>
          <Grid.Column floated='right' width={6}>
            <Image bordered rounded size='large' src={map} />
            <List>
              <List.Item>Harju maakond, Tallinn, Kesklinn,</List.Item>
              <List.Item>Vana-Lõuna 39, 10135 Estonia</List.Item>
              <List.Item></List.Item>
              <List.Item as="a" href="mailto:info@bondstream.trade">Email: info@bondstream.trade</List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default ContactPage;