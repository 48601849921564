import React, { useState } from 'react'
import { FooterContainer, HeaderContainer } from '../../../common/'
import { HomePage, ContactPage, Verifier, PrivacyPage } from "../../../containers"
import {
    Segment,
    Container,
    Icon,
    Button,
    Menu,
    Sidebar
} from 'semantic-ui-react'
import { Media, menuItems } from "../../../shared/shared"


const MobileLayout = () => {

    const [status, setStatus] = useState({ sidebarOpened: false })

    const [active, setActiveMenu] = useState("Home")

    const updateMenu = (view) => {
        setActiveMenu(view)
        handleSidebarHide()
    }

    const renderView = () => {
        if (active === "Document Verifier") {
            return <Verifier></Verifier>
        } else if (active === "Contact Us") {
            return <ContactPage></ContactPage>
        } else if (active === "Privacy Policy") {
            return <PrivacyPage></PrivacyPage>
        } else {
            return <HomePage></HomePage>
        }
    }

    const handleSidebarHide = () => setStatus({ sidebarOpened: false })

    const handleToggle = () => setStatus({ sidebarOpened: true })

    return (
        <Media as={Sidebar.Pushable} at='mobile'>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    inverted
                    onHide={handleSidebarHide}
                    vertical
                    visible={status.sidebarOpened}
                >
                    {menuItems.map((value, index) => {
                        return <Menu.Item as='a'
                            key={index} active={value.toLowerCase() === active.toLowerCase() ? true : false} onClick={() => updateMenu(value)}>
                            {value}
                        </Menu.Item>
                    })}
                </Sidebar>

                <Sidebar.Pusher dimmed={status.sidebarOpened}>
                    <Segment
                        inverted
                        textAlign='center'
                        style={{ minHeight: 350, padding: '1em 0em', backgroundImage: 'url(/bgimage.jpg)', backgroundRepeat:'no-repeat',backgroundSize:'cover' }}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size='large'>
                                <Menu.Item onClick={handleToggle}>
                                    <Icon name='sidebar' />
                                </Menu.Item>
                                <Menu.Item position='right'>
                                    <Button as='a' inverted href="https://mobile.bondstream.trade/" target="_blank">
                                        Log in
                                    </Button>
                                </Menu.Item>
                            </Menu>
                        </Container>
                        <HeaderContainer view={active} />
                    </Segment>
                    {renderView()}
                    <FooterContainer></FooterContainer>
                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Media>
    )
}

export default MobileLayout;