import React from 'react'
import {
    Button,
    Container,
    Menu
} from 'semantic-ui-react'
import { menuItems } from '../../shared/shared'

const MenuContainer = (props) => {
    const { active, updateMenu } = props;

    return (
        <Menu
            fixed={null}
            inverted={true}
            pointing={true}
            secondary={true}
            border={false}
            style={{border: "none"}}
            size='large'>
            <Container>
                {menuItems.map((value, index) => {
                    return <Menu.Item as='a'
                        key={index} active={value.toLowerCase() === active.toLowerCase() ? true : false} onClick={() => updateMenu(value)}>
                        {value}
                    </Menu.Item>
                })}
                <Menu.Item position='right'>
                    <Button as='a' href='https://systems.bondstream.trade' target="_blank" inverted={true}>
                        Log in
          </Button>
                </Menu.Item>
            </Container>
        </Menu>
    )
}

export default MenuContainer;