import React from 'react'
import {
    Container,
    Grid,
    Header,
    List,
    Segment,
} from 'semantic-ui-react'

import { menuItems } from "../../shared/shared"


const FooterContainer = (props) => {
    const { updateMenu } = props

    return (
        <Segment inverted vertical style={{ padding: '5em 0em' }}>
            <Container>
                <Grid columns={3} divided inverted stackable>
                    <Grid.Row>
                        <Grid.Column width={5} style={{ textAlign: "center" }}>
                            <Header inverted as='h4' content='FIND US' />
                            <List link inverted>
                                <List.Item>Harju maakond, Tallinn, Kesklinn, </List.Item>
                                <List.Item>Vana-Lõuna 39, 10135 Estonia</List.Item>
                                <List.Item></List.Item>
                                <List.Item as="a" href="mailto:info@bondstream.trade">Email: info@bondstream.trade</List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={5} style={{ textAlign: "center" }}>
                            <Header inverted as='h4' content='OPENING HOURS (GMT +3)' />
                            <List link inverted>
                                <List.Item>Monday  Friday: 9AM to 5PM</List.Item>
                                <List.Item>Saturday & Sunday: Closed</List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column width={5} floated='right' style={{ textAlign: "center" }}>
                            <Header inverted as='h4' content='QUICK LINKS' />
                            <List link inverted>
                                {menuItems.map((value, index) => {
                                    return <List.Item as='a'
                                        key={index} onClick={() => updateMenu(value)}>
                                        {value}
                                    </List.Item>
                                })}
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    )
}

export default FooterContainer;