import React from 'react'
import { DesktopLayout,MobileLayout }from '../../bondstream/layouts/'
import { MediaContextProvider } from '../../shared/shared'


const ResponsiveContainer = () => {

    return (
        <MediaContextProvider>
            <DesktopLayout></DesktopLayout>
            <MobileLayout></MobileLayout>
        </MediaContextProvider>
    )
}

export default ResponsiveContainer;
