import React from 'react'
import {
    Grid,
    Header,
    Segment,
  } from 'semantic-ui-react'
import './MiscSection.css'


const MiscSection = () => {
    return (
        <Segment className={"topic"} style={{ padding: '0em' }} vertical>
          <Grid celled='internally' columns='equal' stackable>
            <Grid.Row textAlign='center'>
              <Grid.Column style={{ paddingBottom: '3em', paddingTop: '3em' }}>
                <Header as='h3' style={{ fontSize: '2em' }}>
                  BONDSTREAM
                </Header>
                <p  className={"divider"}>The use of standard settlement infrastructure, combined with
                Bondstream allows us to offer an innovative level of transparency and security for both Issuers and Investors.</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
    )
}

export default MiscSection;